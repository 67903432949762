import { useState } from 'react'
import api from './api';
import Validations from './Validations';

import AchemedLoadingBlock from './components/AchemedLoadingBlock';
import InputCPF from './components/InputCPF';
import InputDate from './components/InputDate';
import InputPhoneMobile from './components/InputPhoneMobile';

import LogoHorizontal from './assets/logo-achemed-branco-horizontal.png';

import './App.css';
import AchemedCTA from './components/AchemedCTA';

function App() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    name: false,
    cpf: false,
    email: false,
    phoneMobile: false,
    birth: false,
  })

  const [name, setName] = useState("")
  const [cpf, setCpf] = useState("")

  const [birth, setBirth] = useState("")
  const [email, setEmail] = useState("")
  const [phoneMobile, setPhoneMobile] = useState("")

  const [status, setStatus] = useState('')

  const verifyCpf = (ev) => {
    ev.preventDefault()

    let errors = {
      name: false,
      cpf: !Validations.CPFValid(cpf),
      email: false,
      phoneMobile: false,
      birth: false
    }

    setError(errors)

    let validations = true

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && errors[key] === true) {
        validations = false;
      }
    }

    if (validations) {
      const cpfOnlyDigits = cpf.replace(/[^\d]+/g, '');

      setLoading(true)

      api.get(`api/achemed/customers/search/cpf/${cpfOnlyDigits}`)
        .then(response => {
          response.data.status === '400'
            ? setStatus('naoCadastrado')
            : setStatus('cadastrado')
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const onSubmit = (ev) => {
    ev.preventDefault()
    var errors = {
      name: name.replaceAll(' ', '') === '' ? true : false,
      cpf: !Validations.CPFValid(cpf),
      email: email.replaceAll(' ', '') === '' ? false : !Validations.emailValid(email),
      phoneMobile: phoneMobile.replaceAll(' ', '') === '' ? false : !Validations.PhoneMobile(phoneMobile),
      birth: Validations.DateDDMMAAAAInvalid(birth)
    }
    setError(errors)
    var validations = true

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && errors[key] === true) {
        validations = false;
      }
    }

    if (validations) {
      var data = {
        cpf: cpf.replaceAll(".", "").replaceAll("-", ""),
        name: name,
        birth: birth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3$2$1'),
        recovery_email: email.replaceAll(" ", ""),
        mobile: phoneMobile.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
        dependent: true
      }

      setLoading(true)

      api.post("/api/customers/web", data).then((response) => {
        setStatus('sucesso')
      }).catch((error) => {
        console.error(error);
        setStatus('erro')
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  return (
    <>
      {loading && <AchemedLoadingBlock />}

      <form onSubmit={onSubmit}>
        <nav className="navbar achemed-navbar bg-blue navbar-dark">
          <div className="d-flex align-items-center navbar-brand ps-5 justify-content-center">
            <img style={{ marginRight: 10 }} width={200} alt='logo' src={LogoHorizontal} />
          </div>
        </nav>

        <div className='body d-flex align-items-center'>
          <div className="row col-12 col-md-9 col-xl-6 col-xxl-4 m-auto">
            {
              status === '' && (
                <div className='signup-form p-3 rounded-3'> 
                  <h2 className="text my-4 text-center text-blue">Faça seu cadastro</h2>

                  <div className={`col-12 mb-3 d-flex justify-content-between flex-column align-items-center`}>
                    <div className='col-12 col-sm-9 col-lg-6'>
                      <label className="form-label-input">CPF*</label>
                      <InputCPF value={cpf} onChange={setCpf} />
                      {error.cpf && <span className="error" >Campo Inválido</span>}
                    </div>

                    <div className="col-12 col-sm-9 col-lg-6 d-flex">
                      <button className="continue-button bg-blue mt-4 rounded-5 px-3 w-100"
                      onClick={verifyCpf}>Continuar</button>
                    </div>

                  </div>
                </div>
              )
            }

            {
              status === 'naoCadastrado' && (
                <div className='signup-form p-4 rounded-3'>
                  <h2 className="text my-4 text-center text-blue">Faça seu cadastro</h2>

                  <div className='col-12 mb-3'>
                    <label className="form-label-input">CPF*</label>
                    <InputCPF value={cpf} />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="form-label-input">Nome*</label>
                    <input className="form-control" value={name} type="text" name="name" placeholder="Nome" onChange={value => setName(value.target.value.toUpperCase())} />
                    {error.name && <span className="error" >Campo Inválido</span>}
                  </div>

                  <div className="col-12 mb-3">
                    <label className="form-label-input">Email*</label>
                    <input className="form-control" value={email} onChange={value => setEmail(value.target.value)} type="text" name="email" placeholder="Email" />
                    {error.email && <span className="error" >Campo Inválido</span>}
                  </div>

                  <div className="col-12 mb-3">
                    <label className="form-label-input">Data de Nascimento*</label>
                    <InputDate value={birth} onChange={setBirth} />
                    {error.birth && <span className="error" >Campo Inválido</span>}
                  </div>

                  <div className="col-12 mb-3">
                    <label className="form-label-input">Celular*</label>
                    <InputPhoneMobile value={phoneMobile} onChange={setPhoneMobile} />
                    {error.phoneMobile && <span className="error" >Campo Inválido</span>}
                  </div>

                  <div className="col-12 col-sm-9 col-lg-6 m-auto">
                    <button type="submit" className="save-button bg-blue p-3 mt-4 rounded-5 px-3 w-100">Salvar</button>
                  </div>
                </div>
              )
            }

            {
              status === 'cadastrado' && (
                <div className="col-12 mb-3">
                  <h3 className="mt-5 text-center text-blue">Você já está cadastrado!</h3>
                  <AchemedCTA />
                </div>
              )
            }

            {
              status === 'sucesso' && (
                <div className="col-12 mb-3">
                  <h2 className="my-4 text-center text-blue">Parabéns!</h2>
                  <h3 className="mt-4 text-center text-blue">Seu cadastro foi concluído com sucesso</h3>
              
                  <AchemedCTA/>
                </div>
              )
            }

            {
              status === 'erro' && (
                <div className="col-12 mb-3">
                  <h3 className="mt-4 text-center text-blue">Houve um erro</h3>
                  <h4 className="mt-4 text-center text-blue">Não foi possível fazer o cadastro</h4>
                </div>
              )
            }
          </div>
        </div>

        <footer className='footer bg-blue text-center'>
          © Achemed - { new Date().getFullYear() } - Todos os direitos reservados.
        </footer>
      </form>
    </>
  )
}

export default App
